import Image from 'next/image';
import { useRouter } from 'next/router';
import { Fragment, useEffect, useState } from 'react';

import {
  CreateHostModal,
  NavigationMenuContainer,
  ToastContainer
} from '@/components/atomic/molecules';
import { Button } from '@/components/atomic/nuclei';
import { DEFAULT_TOAST_CONFIG, staticMediaStoreBaseURL } from '@/config/common';
import useScrollVisibility from '@/hooks/useScrollVisibility';
import { getPlannerName } from '@/services/identity.service';
import { PAGE_NAME, getPageURL } from '@/services/plannerPortal.service';
import { CREATE_EVENT_TYPEFORM_URL } from '@/services/url.service';

const HeaderOrganism = ({
  headerContainerStyle = '',
  headerStyle = 'w-full',
  isMenuOpen,
  setIsMenuOpen
}) => {
  const router = useRouter();
  const [showToast, setShowToast] = useState(DEFAULT_TOAST_CONFIG);
  const [plannerName, setPlannerName] = useState(null);

  const [openAddHostModal, setOpenAddHostModal] = useState(false);

  const isHeaderVisible = useScrollVisibility({
    additionalOperations: () => {
      setIsMenuOpen(false);
    },
    initialVisibility: false
  });

  const showNavigationOptions = Boolean(plannerName);

  useEffect(() => {
    setPlannerName(getPlannerName());
  }, []);

  return (
    <Fragment>
      <div
        className={`fixed flex bg-white h-[64px] z-30 px-10 page-header w-full ${
          isHeaderVisible ? 'top-[-65px]' : 'top-0'
        } ${headerContainerStyle}`}
      >
        <div
          className={`flex relative z-50 justify-between items-center self-center ${headerStyle}`}
        >
          <div className='flex gap-10'>
            <div className='self-center w-64'>
              <Image
                alt='hafla-logo'
                className='cursor-pointer'
                height={0}
                onClick={() =>
                  router.push(
                    getPageURL({
                      pageName: PAGE_NAME.WELCOME.label
                    })
                  )
                }
                priority={true}
                src={`${staticMediaStoreBaseURL}/icons/hafla.svg`}
                style={{ height: 32, width: 100 }}
                width={0}
              />
            </div>
          </div>
          <div className='flex gap-6 h-10'>
            {showNavigationOptions && (
              <Button
                {...{
                  className:
                    'text-white font-medium text-base bg-brand-gradient rounded-lg px-4',
                  iconHeight: 20,
                  iconPosition: 'Right',
                  iconURL: `${staticMediaStoreBaseURL}/icons/navigate-white-icon.svg`,
                  iconWidth: 20,
                  label: 'Quick Find',
                  onClick: () =>
                    router.push(
                      getPageURL({
                        pageName: PAGE_NAME.NAVIGATION.label
                      })
                    )
                }}
              />
            )}
            <div className='w-10 h-10'>
              <div
                className='inner user-event-icon hover:bg-brand-gradient rounded w-9 h-9 relative'
                onClick={() => window.open(CREATE_EVENT_TYPEFORM_URL, '_blank')}
              >
                <Image
                  alt='user event brand'
                  className='block user-event-brand-icon relative left-[5px] top-[6px] cursor-pointer'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/user-event-brand-icon.svg`}
                  style={{ height: 26, width: 26 }}
                  width={0}
                />
                <Image
                  alt='user event white'
                  className='hidden user-event-white-icon relative left-[5px] top-[6px] cursor-pointer'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/user-event-white-icon.svg`}
                  style={{ height: 26, width: 26 }}
                  width={0}
                />
              </div>
            </div>
            <div className='w-10 h-10'>
              <div
                className='inner hover:bg-brand-gradient rounded w-9 h-9 search-icon cursor-pointer'
                onClick={() =>
                  router.push(
                    getPageURL({
                      pageName: PAGE_NAME.PRODUCT_SEARCH.label
                    })
                  )
                }
              >
                <Image
                  alt='product search brand'
                  className='relative left-[5px] top-[6px] block search-brand-icon cursor-pointer'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/search-brand-icon.svg`}
                  style={{ height: 26, width: 26 }}
                  width={0}
                />
                <Image
                  alt='product search white'
                  className='relative left-[5px] top-[6px] hidden search-white-icon cursor-pointer'
                  height={0}
                  src={`${staticMediaStoreBaseURL}/icons/search-white-icon.svg`}
                  style={{ height: 26, width: 26 }}
                  width={0}
                />
              </div>
            </div>
            {showNavigationOptions && (
              <NavigationMenuContainer
                {...{
                  isMenuOpen,
                  plannerName,
                  setIsMenuOpen,
                  setOpenAddHostModal
                }}
              />
            )}
            <ToastContainer {...{ showToast, setShowToast }} />
            {openAddHostModal && (
              <CreateHostModal {...{ setOpenAddHostModal, setShowToast }} />
            )}
          </div>
        </div>
      </div>
      {isMenuOpen && (
        <div className='fixed w-full h-full top-0 left-0 black-overlayer z-20' />
      )}
    </Fragment>
  );
};

export default HeaderOrganism;
